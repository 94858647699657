import { useTranslation } from 'next-i18next'
import type { ReactElement } from 'react'
import { useMemo } from 'react'

import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectTheme } from '@reduxCommonApp/commonApp.selectors'
import { setTheme } from '@reduxCommonApp/commonApp.slice'

import CookiesKeys from '@constants/cookies/cookiesKeys'
import MoonIcon from '@iconscout/unicons/svg/line/moon.svg'
import SunIcon from '@iconscout/unicons/svg/line/sun.svg'
import { cookie } from '@services/cookies/cookies'
import { ThemeNames } from '@themes/themes'

export const themeIcons: Record<ThemeNames, ReactElement> = {
  [ThemeNames.DARK]: <SunIcon />,
  [ThemeNames.LIGHT]: <MoonIcon />,
}

const useSwitchTheme = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('common')
  const themeKey = useAppSelector(selectTheme)

  const themeLabels = useMemo<Record<ThemeNames, string>>(
    () =>
      Object.fromEntries(Object.values(ThemeNames).map(themeName => [themeName, t(themeName)])) as Record<
        ThemeNames,
        string
      >,
    []
  )

  return useMemo(() => {
    const switchThemeHandler = () => {
      const currentTheme = themeKey === ThemeNames.LIGHT ? ThemeNames.DARK : ThemeNames.LIGHT
      cookie.setCookie(CookiesKeys.THEME, currentTheme, 2592000) // expires 30 days
      void dispatch(setTheme(currentTheme))
    }

    return {
      icon: themeIcons[themeKey],
      switchThemeHandler,
      themeLabel: themeLabels[themeKey],
    }
  }, [themeKey])
}

export default useSwitchTheme
